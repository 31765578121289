<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row>
            <b-col cols="12">
                <!-- 1 -->
                <b-card no-body class="mb-4">
                    <!-- Search Control -->
                    <b-card-body class="d-flex justify-content-between align-items-end">
                        <div class="row w-100">
                            <div class="col-3">
                                <label>Filter Type</label>
                                <b-form-select
                                    v-model="applicantType"
                                    :options="[
                                        { value: null, text: 'Select an Applicant Type' },
                                        { text: 'Account ID', value: 'id' },
                                        { text: 'Applicant', value: 'applicant' },
                                        { text: 'CoApplicant ID', value: '_Additional_Information.coApplicantID' },
                                        { text: 'CoApplicant Name', value: '_Additional_Information.coApplicant' },
                                    ]"
                                    @change="searchValue = null"
                                ></b-form-select>
                            </div>
                            <div class="col-3">
                                <label>Filter Operator</label>
                                <b-form-select
                                    v-model="filterOperator"
                                    :options="[
                                        { value: null, text: 'Select a filter' },
                                        { value: 'STARTS_WITH', text: 'Starts With' },
                                        { value: 'EQUALS', text: 'Equals' },
                                    ]"
                                ></b-form-select>
                            </div>
                            <div class="col-4" v-if="applicantType == 'applicant'">
                                <label>Select an Applicant</label>
                                <SearchSelect
                                    store="customer/get_allCustomers"
                                    text="firstName"
                                    value="id"
                                    @option-selected="handleOption"
                                />
                            </div>
                            <div class="col-3" v-if="applicantType != 'applicant'">
                                <label>Filter Value</label>
                                <b-form-input
                                    id="filter-input"
                                    v-model="searchValue"
                                    type="search"
                                    placeholder="Type to Search"
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="d-flex">
                            <!-- Clear btn -->
                            <b-button
                                class="mr-2"
                                style="min-width: 150px"
                                @click="clearData"
                                variant="outline-primary"
                            >
                                Clear selection
                            </b-button>

                            <b-button
                                style="min-width: 150px"
                                :disabled="applicantType == null || !searchValue"
                                @click="getData"
                                variant="primary"
                            >
                                Search
                            </b-button>
                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Data Table -->
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="items"
                        :filter="filter"
                        :fields="fields"
                        hover
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        no-local-sorting
                    >
                        <template #cell(id)="data"> #{{ data.item.id }} </template>
                        <template #cell(creationDate)="data"> {{ $formatDate(data.item.creationDate) }} </template>
                        <template #cell(loanAmount)="data"> {{ $formatCurrency(data.item.loanAmount) }} </template>

                        <template #cell(accountState)="data">
                            <b-badge :variant="getStateVariant(data.item.accountState)" class="text-capitalize mr-1">
                                {{ data.item.accountState }}
                            </b-badge>
                            <b-badge
                                v-if="data.item.accountSubState"
                                :variant="getStateVariant(data.item.accountSubState)"
                                class="text-capitalize"
                            >
                                {{ data.item.accountSubState }}
                            </b-badge>
                        </template>

                        <template #cell(action)="data">
                            <b-button variant="primary btn-sm" @click="goToDetails(data.item)"> View Details </b-button>
                        </template>
                    </b-table>
                    <!-- End Section -->

                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                @change="getData"
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getData"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import SearchSelect from '@/components/SearchSelect.vue';
export default {
    name: 'applications',
    data: () => ({
        page: {
            title: 'applications',
        },
        applicantType: null,
        filterOperator: null,
        sortBy: 'creationDate',
        sortDesc: true,
        searchValue: null,
        sortColumns: {
            id: '[CreatedLoans].[Id]',
            loanId: '[CreatedLoans].[LoanId]',
        },
        fields: [
            {
                key: 'id',
                label: 'Account ID',
                sortable: true,
            },
            {
                key: 'accountHolderId',
                label: 'Account Holder ID',
            },
            {
                key: 'accountHolderName',
                label: 'Account Holder Name',
            },
            {
                key: 'loanName',
                label: 'Loan Type',
                sortable: true,
            },
            {
                key: 'loanAmount',
                label: 'Amount',
                sortable: true,
            },
            {
                key: 'accountState',
                label: 'State',
                sortable: true,
            },
            {
                key: 'creationDate',
                label: 'Creation Date',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Action',
                sortable: true,
            },
        ],
        loading: false,
        filter: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        items: [],
        routes: [
            {
                path: '/loans/active',
                status: 'ACTIVE',
            },
            {
                path: '/loans/approved',
                status: 'APPROVED',
            },
            {
                path: '/loans/pending-approval',
                status: 'PENDING_APPROVAL',
            },
            {
                path: '/lons/active-in-arrears',
                status: 'ACTIVE_IN_ARREARS',
            },
            {
                path: '/loans/closed',
                status: 'CLOSED',
            },
        ],
    }),
    components: {
        SearchSelect,
    },
    methods: {
        clearData() {
            this.searchValue = null;
            this.applicantType = null;
            this.filterOperator = null;
            this.getData();
        },
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('mambu/get_mambuLoans', {
                skip: this.currentPage,
                take: this.perPage,
                sortDirection: this.sortDesc ? 'desc' : 'asc',
                sortProperty: this.sortBy,
                status: this.routes.find((el) => el.path == this.$route.path)?.status || '',
                applicantType: this.applicantType,
                filterValue: this.searchValue,
                filterField: this.applicantType,
                filterOperator: this.filterOperator,
            });
            this.items = response?.dataResult?.payload ?? [];
            this.totalRows = response?.dataRecords ?? 0;
            this.loading = false;
        },
        toJson(str) {
            const jsonString = JSON.parse(str);

            return jsonString;
        },
        goToDetails(item) {
            this.$router.push(`all/${item.id}`);
        },
        getStateVariant(state) {
            switch (state) {
                case 'ACTIVE':
                    return 'success';
                case 'APPROVED':
                    return 'primary';
                case 'PENDING_APPROVAL':
                    return 'warning';
                case 'ACTIVE_IN_ARREARS':
                    return 'danger';
                case 'CLOSED':
                    return 'dark';
                case 'PARTIALLY_DISBURSED':
                    return 'warning';
                case 'LOCKED':
                    return 'dark';
                case 'WRITTEN_OFF':
                    return 'dark';
                default:
                    return 'info';
            }
        },
        handleOption(option) {
            this.searchValue = option.nationalID || null;
        },
    },
    computed: {},
    watch: {
        '$route.path'() {
            this.getData();
        },
        sortBy() {
            this.getData();
        },
        sortDesc() {
            this.getData();
        },
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
